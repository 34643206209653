import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/features/blog/layouts/PostLayout/PostLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
  .material-theme {
    background-color: #263238;
    color: #EEFFFF;
  }
  .material-theme .mtki { font-style: italic; }
  .material-theme .mtk3 { color: #89DDFF; }
  .material-theme .mtk1 { color: #EEFFFF; }
  .material-theme .mtk9 { color: #F07178; }
  .material-theme .mtk12 { color: #C3E88D; }
  .material-theme .mtk4 { color: #546E7A; }
  .material-theme .mtk7 { color: #FFCB6B; }
  .material-theme .mtk10 { color: #C792EA; }
  .material-theme .mtk8 { color: #82AAFF; }
  .material-theme .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(255, 255, 255, 0.1));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(255, 255, 255, 0.5));
  }
`}</style>


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/de1d0624ca000724073d99d7fc9f007d/8ccd0/setup-automated-migrations-with-typeorm.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAHK5BBhKCn/xAAbEAACAQUAAAAAAAAAAAAAAAABAgADEBESMf/aAAgBAQABBQIYFNd4/QxhY2//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAQIS/9oACAEDAQE/Acoq/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8Bqv/EABoQAAMBAAMAAAAAAAAAAAAAAAARIQEQIjH/2gAIAQEABj8C2VHdpEMp7x//xAAcEAEAAgIDAQAAAAAAAAAAAAABABExUSFBYaH/2gAIAQEAAT8hYRoPs7B9EIlwTiHKKU7yYruf/9oADAMBAAIAAwAAABBQP//EABYRAQEBAAAAAAAAAAAAAAAAAAERAP/aAAgBAwEBPxBVmQJd/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERMf/aAAgBAgEBPxCNGtP/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMUGx/9oACAEBAAE/EGm4tGwCNVTSAvM9hkAVFcJR46fIcBiNp1inVP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Setup automated Database Migrations with TypeORM CLI",
            "title": "Setup automated Database Migrations with TypeORM CLI",
            "src": "/static/de1d0624ca000724073d99d7fc9f007d/e5166/setup-automated-migrations-with-typeorm.jpg",
            "srcSet": ["/static/de1d0624ca000724073d99d7fc9f007d/f93b5/setup-automated-migrations-with-typeorm.jpg 300w", "/static/de1d0624ca000724073d99d7fc9f007d/b4294/setup-automated-migrations-with-typeorm.jpg 600w", "/static/de1d0624ca000724073d99d7fc9f007d/e5166/setup-automated-migrations-with-typeorm.jpg 1200w", "/static/de1d0624ca000724073d99d7fc9f007d/8ccd0/setup-automated-migrations-with-typeorm.jpg 1792w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`I found myself struggling to find a simple guide on how to set up automated database migrations with the TypeORM CLI. It can be quite overwhelming to get started, despite the fact that it's a very common task and seemingly simple task.`}</p>
    <p>{`I was looking for simple answers to the following questions:`}</p>
    <ul>
      <li parentName="ul">{`How do I generate the initial migration automatically (based on the created entities - when bootstrapping a new project)?`}</li>
      <li parentName="ul">{`How do I avoid having to specify the migrations directory every time I run a migration?`}</li>
      <li parentName="ul">{`What does the workflow look like?`}</li>
    </ul>
    <p>{`It turns out it's quite simple, `}<em parentName="p">{`if everything is correctly configured`}</em>{`, but the documentation is a bit scattered and the CLI is lacking proper error/warning messages in my experience. So here's a simple guide to setting up automated database migrations with TypeORM that I wish I had when I started.`}</p>
    <h2>{`Setup`}</h2>
    <h3>{`1. Ensure you have a `}<inlineCode parentName="h3">{`ormconfig.json`}</inlineCode>{` / `}<inlineCode parentName="h3">{`ormconfig.ts`}</inlineCode>{` file in your project root`}</h3>
    <p>{`This file is used to configure the TypeORM CLI which you'll be using to generate and run migrations.`}</p>
    <h3>{`2. Ensure that the `}<inlineCode parentName="h3">{`entities`}</inlineCode>{` array in your `}<inlineCode parentName="h3">{`ormconfig`}</inlineCode>{` is configured (!)`}</h3>
    <p>{`This is the most important part. The CLI uses this to generate migrations based on the entities you've defined. The CLI will provide no warnings if it cannot find any entities.`}</p>
    <p>{`Setup might vary depending on your framework, but my `}<inlineCode parentName="p">{`ormconfig.ts`}</inlineCode>{` looks like this:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "ts",
      "data-index": "0"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`DataSource`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`typeorm`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`SnakeNamingStrategy`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`typeorm-naming-strategies`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// Optional: I like snake_case for my tables and camelCase for my TypeScript entities`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`export`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`default`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`new`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`DataSource`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// Your database connection options here`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`entities`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` [`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`src/**/*.entity.ts`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// This is the important part`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`namingStrategy`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`new`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`SnakeNamingStrategy`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// Optional`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span></code></pre>
    <h3>{`3. Setup package.json scripts to run the migrations`}</h3>
    <p>{`To reduce the amount of commands I need to remember to run and write, I found `}<a parentName="p" {...{
        "href": "https://github.com/sramocki/nestjs-typeorm-example-migrations"
      }}>{`this great example`}</a>{` of how to setup some basic migration commands to avoid having to specify the migrations directory params.`}</p>
    <p>{`It uses `}<inlineCode parentName="p">{`cross-var`}</inlineCode>{` and `}<inlineCode parentName="p">{`ts-node`}</inlineCode>{` so remember to have that installed.`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "json",
      "data-index": "1"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`scripts`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`":`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`typeorm`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`":`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`ts-node -r tsconfig-paths/register ./node_modules/typeorm/cli -d ormconfig.ts`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`",`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`migration:create`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`":`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`cross-var ts-node -r tsconfig-paths/register ./node_modules/typeorm/cli migration:create ./src/database/migrations/$npm_config_name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`",`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`migration:generate`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`":`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`cross-var npm run typeorm -- migration:generate ./src/database/migrations/$npm_config_name`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`",`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`migration:run`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`":`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`npm run build && npm run typeorm -- migration:run`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`",`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`migration:revert`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`":`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`npm run typeorm -- migration:revert`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`},`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`devDependencies`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`":`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`cross-var`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`":`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`^1.1.0`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`",`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`ts-node`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`":`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`^10.4.0`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`"`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span></code></pre>
    <p>{`Adjust the `}<inlineCode parentName="p">{`src/database/migrations`}</inlineCode>{` path to match your project structure.`}</p>
    <h2>{`The workflow`}</h2>
    <p>{`Now that you have the basic setup, below is a simple workflow for generating and running migrations.`}</p>
    <h3>{`Initial migration`}</h3>
    <p>{`This was the part that I found the most confusing. When you're bootstrapping a new project, you want to generate the initial migration based on the entities you've created.`}</p>
    <p>{`Once you have created your entities, you can run the following command to generate the initial migration:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "2"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`npm run migration:generate --name=initial-db`}</span></span></span></code></pre>
    <p>{`It will generate a migration file in your `}<inlineCode parentName="p">{`src/database/migrations`}</inlineCode>{` directory, called something like `}<inlineCode parentName="p">{`1722856393104-initial-db.ts`}</inlineCode>{`, that looks something like this:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "ts",
      "data-index": "3"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`MigrationInterface`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`QueryRunner`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`typeorm`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`export`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`InitialDb1722856393104`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`implements`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`MigrationInterface`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`InitialDb1722856393104`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`public`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`async`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`up`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`queryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`QueryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`):`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Promise`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`void`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`await`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`queryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`query`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\``}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`        CREATE TABLE "user"`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`        (`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`            "id"        UUID                 DEFAULT gen_random_uuid() NOT NULL,`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`            "name"      varchar     NOT NULL,`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`            "createdAt" timestamptz NOT NULL DEFAULT now(),`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`            "updatedAt" timestamptz NOT NULL DEFAULT now(),`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`            CONSTRAINT "PK_cace4a159ff9f2512dd42373760" PRIMARY KEY ("id")`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`        )`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk12"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`public`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`async`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`down`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`queryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`QueryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`):`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Promise`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`void`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`await`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`queryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`query`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`DROP TABLE "user"`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span></code></pre>
    <p><strong parentName="p">{`Important`}</strong>{`: The CLI will not warn you if it cannot find any entities. So if you run this command and nothing happens, make sure that the `}<inlineCode parentName="p">{`entities`}</inlineCode>{` array in your `}<inlineCode parentName="p">{`ormconfig`}</inlineCode>{` is correct.`}</p>
    <p>{`If you were to run `}<inlineCode parentName="p">{`npm run migration:generate --name=some-migration`}</inlineCode>{` without having changed any entities (after having run the initial migration, of course), you would get the error `}<inlineCode parentName="p">{`No changes in database schema were found - cannot generate a migration`}</inlineCode>{`.`}</p>
    <h3>{`Running migrations`}</h3>
    <p>{`You can run your migrations with:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "4"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`npm run migration:run`}</span></span></span></code></pre>
    <p>{`Let's go ahead and test if it can generate a migration based on the entities we've changed.`}</p>
    <h3>{`Generating migrations from entity changes`}</h3>
    <p>{`Let's say you've changed a column name in your entity:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "diff",
      "data-index": "5"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`@Entity()`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`export class User {`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  @Column()`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`-`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  name: string;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`+`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`  fullName: string;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`}`}</span></span></span></code></pre>
    <p>{`You can now run the following command to generate a migration based on the changes you've made:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "6"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`npm run migration:generate --name=change-user-column`}</span></span></span></code></pre>
    <p>{`It will generate a migration file in your `}<inlineCode parentName="p">{`src/database/migrations`}</inlineCode>{` directory, called something like `}<inlineCode parentName="p">{`1722856393104-change-user-column.ts`}</inlineCode>{`, that looks something like this:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "ts",
      "data-index": "7"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`MigrationInterface`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`QueryRunner`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`typeorm`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`export`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`ChangedNameColumn1722859765490`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`implements`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`MigrationInterface`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`ChangedNameColumn1722859765490`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`public`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`async`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`up`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`queryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`QueryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`):`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Promise`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`void`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`await`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`queryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`query`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`ALTER TABLE "user" RENAME COLUMN "name" TO "full_name"`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`public`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`async`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`down`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`queryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`QueryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`):`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Promise`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`void`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`await`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`queryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk8"
            }}>{`query`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`ALTER TABLE "user" RENAME COLUMN "full_name" TO "name"`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span></code></pre>
    <h3>{`Manually creating migrations`}</h3>
    <p>{`If you want to create a new migration manually, you can run the following command:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "bash",
      "data-index": "8"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`npm run migration:create --name=my-new-migration`}</span></span></span></code></pre>
    <p>{`This will create a new migration file in your `}<inlineCode parentName="p">{`src/database/migrations`}</inlineCode>{` directory, called something like `}<inlineCode parentName="p">{`1722856393104-my-new-migration.ts`}</inlineCode>{`, with an empty `}<inlineCode parentName="p">{`up`}</inlineCode>{` and `}<inlineCode parentName="p">{`down`}</inlineCode>{` method.`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "ts",
      "data-index": "9"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`MigrationInterface`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`QueryRunner`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`typeorm`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`';`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`export`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`MyNewMigration1722859913782`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`implements`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`MigrationInterface`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`public`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`async`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`up`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`queryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`QueryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`):`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Promise`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`void`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// Your migration code here`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`public`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`async`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`down`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`queryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`QueryRunner`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`):`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`Promise`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`void`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk4 mtki"
            }}>{`// Your migration code here`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`}`}</span></span></span></code></pre>
    <h2>{`Troubleshooting`}</h2>
    <h3>{`No changes in database schema were found`}</h3>
    <p>{`If you're getting: `}<inlineCode parentName="p">{`No changes in database schema were found - cannot generate a migration. To create a new empty migration use "typeorm migration:create" command.`}</inlineCode>{`, theres at least two possible reasons for this error:`}</p>
    <ol>
      <li parentName="ol">{`The CLI cannot find any of your entities. Make sure that the entities array in your `}<inlineCode parentName="li">{`ormconfig`}</inlineCode>{` is correct.`}</li>
      <li parentName="ol">{`You haven't changed any entities since the last migration. If you want to create a new empty migration, you can run `}<inlineCode parentName="li">{`npm run migration:create --name=my-new-migration`}</inlineCode>{`.`}</li>
    </ol>
    <h3>{`Unable to open file: ormconfig.ts`}</h3>
    <p>{`Ensure that the path to your `}<inlineCode parentName="p">{`ormconfig.ts`}</inlineCode>{` file is correct.`}</p>
    <h2>{`Summary`}</h2>
    <p>{`I hope this simple guide helps you get started with setting up automated database migrations with TypeORM CLI. It's a simple setup that I've found to be quite effective in my projects. If you have any questions or suggestions, feel free to comment below!`}</p>
    <p>{`I've also written a simple post on setting up TypeOrm with a NestJS project, which you can find `}<a parentName="p" {...{
        "href": "/posts/2024/08/05/nestjs-project-with-typeorm-cli-and-automatic-migrations.md"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      